import React, { Suspense } from "react"

import { isSSR } from "../utils/constants"
import { colors } from "../utils/colors"
import Layout from "../components/layout"
import SEO from "../components/Seo"
import Spinner from "../components/Spinner"

const LegalTerms = React.lazy(() => import("../components/LegalTerms"))

export default () => {
  return (
    <>
      {!isSSR && (
        <Suspense fallback={<Spinner />}>
          <SEO
            title="Política de privacidad"
            keywords={[
              "Legal,términos legales,legales,privacidad,políticas de privacidad",
            ]}
            description="Nosotros respetamos su privacidad y estamos comprometidos con salvaguardar sus derechos al debido proceso y a la protección de sus datos personales, así como cumplir con los requerimientos legales."
          />
          <Layout background={colors.GraySolitude}>
            <LegalTerms title="Política de Privacidad">
              <p>
                iBAN Online S.R.L., una sociedad de responsabilidad limitada
                organizada en la República Dominicana, dotada del RNC No.
                131421725, con su domicilio social ubicado en la Av. Sarasota
                #62 Bella Vista Mall Local 42-A Primer nivel, Santo Domingo,
                República Dominicana, (en lo adelante “IBAN” o “nosotros”), es
                el controlador del procesamiento los datos personales que
                resulten del uso de esta página web y de los servicios que
                brindamos.
              </p>
              <p>
                Nosotros respetamos su privacidad y estamos comprometidos con
                salvaguardar sus derechos al debido proceso y a la protección de
                sus datos personales, así como cumplir con los requerimientos
                legales. Nuestra Política de Privacidad le ofrece una
                explicación respecto al uso de los datos personales que usted
                nos provee, y también sobre cualquier información que podamos
                recolectar de usted.
              </p>
              <p>
                Esta Política de Privacidad es actualizada periódicamente. En
                caso de que la Política sea modificada, IBAN le notificará a
                usted de los cambios a través de nuestra página web y vía correo
                electrónico, diez (10) días antes de la fecha efectiva de las
                modificaciones.
              </p>
              <strong>
                <b> 1. Información recolectada.</b>
              </strong>
              <p>
                1.1. Los datos puede ser recolectados y procesados durante las
                operaciones de nuestra página web o del uso de nuestros
                servicios. Podemos llevar a cabo estas actuaciones mediante los
                siguientes procedimientos:
              </p>
              <p>
                Se recogen datos y recursos en su visita a nuestra página web,
                incluyendo y no limitado a: los datos de localización, weblogs,
                datos de tráfico, y cualquier otra información de comunicación.
              </p>
              <p>
                Cualquier formulario llenado en nuestra página web o en nuestras
                filiales, o cuando se completa una aplicación, nos permite
                recoger datos tales como el registro de información.
              </p>
              <p>
                Si por alguna razón usted se comunica (incluyendo el correo
                electrónico, teléfono, &#34;Chat Online&#34;, etc.) con nuestro
                personal, podemos recolectar información. Requerimiento de datos
                de su historial de crédito de los registros pertinentes,
                organismos y terceros (incluyendo sociedades de información
                crediticias (“buró de crédito”)).
              </p>
              <p>
                1.2. Los datos que recolectamos cuando usted aplica a nuestros
                servicios, por lo general son los siguientes: nombre; número de
                Cédula de Identidad y Electoral u otra forma de identificación;
                dirección; dirección de correo electrónico; número de teléfono
                móvil; fecha de nacimiento; empleo; nombre del empleador; número
                de teléfono del trabajo; ingresos mensuales; registros de
                llamadas; información sobre sus pasivos y deudas con nosotros o
                terceros, actuales y pasados; cualquier otra información que
                usted elija para proporcionarnos a través de su uso del
                &#34;Chat Online&#34;, teléfono, correo electrónico y fax, y que
                pueda ser relevante para el cumplimiento de las obligaciones y
                compromisos asumidos por usted frente a IBAN.Sin embargo, en
                algunas circunstancias, IBAN podrá requerir datos o documentos
                adicionales para apoyar su solicitud.
              </p>
              <strong>
                <b>2. Bases y propósitos de procesamiento </b>
              </strong>
              <p>
                2.1. Nosotros procesamos su información personal conforme las
                previsiones de la Ley No. 172-13 que tiene por objeto la
                protección integral de los datos personales asentados en
                archivos, registros públicos, bancos de datos u otros medios
                técnicos de tratamiento de datos destinados a dar informes (la
                “Ley de Protección de Datos”). El procesamiento de los datos que
                surgen de sus obligaciones contractuales, así como la
                suscripción, a petición del cliente, del contrato de préstamo
                con usted. El procesamiento de datos es necesario para la
                satisfacción de nuestros intereses legítimos, respetando al
                mismo tiempo sus derechos y libertades fundamentales. También
                podemos procesar sus datos sobre la base de su consentimiento.
                Hemos adoptado esta Política de Privacidad, así como otros
                procedimientos internos, con el fin de cumplir con los términos
                y condiciones de la Ley de Protección de Datos y a los fines de
                que usted cuente con información suficiente sobre los fines y
                tratamiento que se le dará a la información que usted
                proporcione a IBAN.
              </p>
              <strong>
                <b>
                  2.2. Los datos personales recolectados serán registrados y
                  guardados electrónicamente y podrán ser usados de las
                  siguientes formas:
                </b>
              </strong>
              <p>
                Para verificar su identidad, posteriormente llevar a cabo
                verificaciones de crédito (incluyendo a través de sociedades de
                información crediticias (“buró de crédito”)) para la evaluación
                de su comportamiento crediticio y de su capacidad para pagar el
                préstamo, así como para ofrecer servicios apropiados antes de
                establecer de una obligación contractual; Para identificar y
                evitar cualquier tipo de fraude, blanqueo de capitales y
                cualquier actividad ilícita y/o criminal; Para comunicarle
                cualquier cambio en las políticas de la empresa incluyendo
                cambios a la política de privacidad; Para recuperar fondos
                adeudados a IBAN; Para asegurar y mejorar la calidad de los
                servicios y ofrecerle nuevos servicios; Para fines estadísticos
                y de investigación de mercado; Para promocionar productos y
                servicios nuestros y de terceros, incluyendo, la adaptación de
                nuestras ofertas hacia las preferencias personales de nuestros
                clientes; Para cumplir con nuestras obligaciones con usted; y
                Cualquier otro uso legal que sea relevante a nuestra relación
                con usted.
              </p>
              <strong>
                <b> 3. Nuestro uso y revelación de su información</b>
              </strong>
              <p>
                3.1. Es posible que revelemos sus datos personales a nuestras
                empresas afiliadas, así como a proveedores de servicios
                descritos en la cláusula
              </p>
              <p> 3.2, para los fines indicados debajo en la Sección</p>
              <p>
                3.2 de esta Política de Privacidad. Cuando enviamos información,
                estamos comprometidos en asegurarnos que el receptor seguirá las
                mismas medidas de seguridad para el almacenamiento y
                procesamiento de sus datos personales, tal como lo hacemos
                nosotros. Usted comprende y está de acuerdo con esta revelación
                de sus datos personales a nuestras empresas afiliadas y
                proveedores de servicios.
              </p>
              <p>
                3.2. Podemos revelar sus datos personales a terceros en los
                siguientes casos:
              </p>
              <p>
                3.2.1. Venta de una parte o la totalidad de nuestra compañía a
                un tercero puede resultar en un intercambio de información;
              </p>
              <p>
                3.2.2. En cualquier momento que seamos requeridos de manera
                legal por una autoridad gubernamental o persona competente
                podremos revelar información sobre usted o sus visitas a
                nuestros sitios web;
              </p>
              <p>
                3.2.3. Para prevenir fraude y colaborar en la protección contra
                fraude para evitar riesgos de crédito;
              </p>
              <p>
                3.2.4. Podemos compartir sus datos personales con proveedores de
                servicios similares así como a las autoridades nacionales
                relevantes y organizaciones no-gubernamentales (incluyendo a las
                sociedades de información crediticias (“buró de crédito”)), con
                el fin de asegurar una evaluación completa de su comportamiento
                crediticio y de su solvencia;
              </p>
              <p>
                3.2.5. Para el procesamiento y cobro de montos adeudados u otros
                pagos debidos por usted bajo el contrato de préstamo, podemos
                compartir sus datos personales con terceros proveedores de
                servicios de procesamiento de pagos;
              </p>
              <p>
                3.2.6. En caso de su incumplimiento o cumplimiento impropio de
                sus obligaciones frente a IBAN, podemos transferir los datos de
                sus deudas descomunales a proveedores de servicios de cobro de
                deudas, extrajudiciales, así como a prestadores de servicios
                legales.
              </p>
              <p>
                3.2.7. Cuando contratamos proveedores de servicio para brindarle
                los servicios a usted, analizamos cómo usted utiliza nuestros
                servicios, para mejorar nuestros servicios o desarrollar nuevos
                servicios. Usted comprende y está de acuerdo con la revelación
                de dichos datos a uno o más terceros para los propósitos
                descritos anteriormente.
              </p>
              <p>
                3.3. Usted reconoce y acepta que sus datos personales podrán ser
                procesados, almacenados y transferidos a una o más sociedad de
                información crediticia (buró de crédito) para fines de reporte
                de crédito. Asimismo, podremos requerir reportes de crédito y
                otras informaciones suyas de una o más sociedad de información
                crediticia (buró de crédito). Usted comprende y está de acuerdo
                de la transferencia de dichos datos a una o más sociedad de
                información crediticia (buró de crédito), y comprende y está de
                acuerdo con nuestro acceso a su información crediticia.
              </p>
              <p>
                3.4. Usted comprende y está de acuerdo que sus datos personales
                podrán ser procesados, almacenados y transferidos fuera de
                República Dominicana y/o a los terceros aquí previstos en la
                medida y cantidad que lo permita el contrato suscrito entre
                usted e IBAN o esta Política de Privacidad, y conforme los
                términos y condiciones de la Ley de Protección de Datos.
              </p>
              <p>
                3.5. Usted debe informarnos en caso de que haya cambios en los
                datos que nos proveyó en el proceso de entrar al contrato de
                crédito o nuestra futura cooperación, así como si ha determinado
                que los datos personales que estamos procesando están
                desactualizados. Favor referirse a la sección 5, debajo, para
                más información en este tema. 3.6. En la medida de lo posible,
                mediante un requerimiento por escrito, donde sus datos
                personales ya no sean necesarios para los fines que fueron
                recolectados, incluyendo ejercer nuestros derechos contractuales
                y legales, eliminaremos sus datos personales de nuestras bases
                de datos y requerimos lo mismo a nuestros contratistas.
              </p>
              <strong>
                <b>4. Almacenamiento de datos personales</b>
              </strong>
              <p>
                4.1. La información proporcionada estará almacenada en
                servidores sujetos a políticas de seguridad. Los datos de su
                transacción con nosotros estarán encriptados para su seguridad.
                4.2. Lugares fuera de la República Dominicana pueden ser
                utilizados para la transferencia de los datos que recolectamos
                de usted. Estos sitios de datos son para almacenamiento o
                procesamiento de su información. La información que compartimos
                fuera de República Dominicana puede ser compartida con un
                personal basado en el proceso de almacenamiento de dichos datos.
                Esto puede incluir procesos como procesamiento de pagos u
                ofertas de soporte por su servicio o necesidades. El personal
                que procesa o almacena información podría trabajar para nuestros
                proveedores fuera de República Dominicana. Conforme lo previsto
                en la Sección 3.4, al enviar sus datos usted ha acordado la
                transferencia y almacenamiento de datos. Asumimos todas las
                acciones razonables para asegurarnos de la seguridad de sus
                datos personales de conformidad con esta Política de Privacidad
                y la Ley de Protección de Datos. 4.3. Debe estar informado de
                que la transferencia de datos por vía electrónica no es
                completamente segura. No podemos garantizar la protección y
                seguridad absoluta de la información, solamente que tomaremos
                todas las acciones razonables para proteger la información que
                nos es enviada o enviamos electrónicamente y que actuaremos con
                estricto apego a la Ley de Protección de Datos. La transmisión
                de cualquier dato por usted corre por su propio riesgo. 4.4.
                Donde aplique, usted tendrá acceso a secciones de nuestro sitio
                web que requerirá una contraseña. Usted es responsable de la
                seguridad y confidencialidad de las contraseñas.
              </p>
              <strong>
                <b>5. Acceso, corrección y actualización de información</b>
              </strong>
              <p>
                Para acceder a su información que ha sido recolectada en
                nuestros sistemas de procesamiento de datos personales diríjase
                al contacto detallado debajo para formular su requerimiento de
                acceso, corrección o actualización:
              </p>
              <p>Correo electrónico: hola@ibanonline.com.do</p>{" "}
              <p>Teléfono: 829 547-0135</p>
              <strong>
                <b>6. Enlaces a páginas operadas por terceros</b>
              </strong>
              <p>
                Puede encontrar enlaces en nuestro sitio web a páginas operadas
                por terceros. Estos enlaces de terceros tienen su propia
                política de privacidad a la que usted se compromete a cuando se
                hace clic en el enlace. Nosotros no somos responsables ni
                aceptamos responsabilidad por los enlaces de terceros y el
                acceso a estos sitios corre por su propio riesgo. Nuestra
                responsabilidad cubre sólo en nuestro sitio web, y por lo tanto
                no nos hacemos responsables por los enlaces de terceros ya que
                no tenemos control sobre ellos.
              </p>
              <b> 7. Comunicaciones comerciales </b>
              <p>
                Al visitar nuestro sitio web al proporcionarnos sus datos
                personales e información de contacto, usted está de acuerdo con
                nuestros derechos para utilizar esta información para la
                comunicación comercial con usted. Siempre se puede optar por no
                recibir futuras comunicaciones comerciales en su perfil de
                usuario en nuestro sitio web. Además, en la parte inferior de
                cada correo electrónico con el mensaje de la comunicación
                comercial proporcionaremos enlace para optar por dejar de
                recibir comunicación comercial posterior.
              </p>
              <strong>
                <b>8. Contáctenos</b>
              </strong>
              <p>
                Siéntase en libertad de contactarnos con sus inquietudes,
                solicitudes, o comentarios acerca de nuestra Política de
                Privacidad. Estamos abiertos a cualquier comunicación vía el
                correo electrónico hola@ibanonline.com.do o al teléfono 829
                547-0135
              </p>
            </LegalTerms>
          </Layout>
        </Suspense>
      )}
    </>
  )
}
